<template>
  <!-- position === 1 && isFinishLastStep -->
  <div class="rank-map-page-container" :class="`lesson` + lesson">
    <transition name="el-fade-in">
      <div
        class="mask-book"
        v-if="position === 1 && isFinishLastStep && maskshow"
      >
        <div class="mask-book-content">
          <span class="font28">CHAPTER {{ chapterNum }}</span>
          <img
            :src="
              require(`@/assets/img/10-Level-progress/progress-level${lesson}.gif`)
            "
            :class="{ glow: position == 1 && steps == finalSteps }"
            alt=""
          />
          <img
            src="@/assets/img/07-Stars/confetti.svg"
            alt=""
            class="confetti-img"
          />
        </div>
      </div>
    </transition>

    <div class="lesson_content">
      <!-- 书本显示 -->
      <div class="customlesson-breakthrough-part">
        <div class="demo">
          <div
            class="customlesson-breakthrough-part-book"
            v-for="(item, index) in 8"
            :key="index"
          >
            <!-- <div class="book-item" v-if="chapterNum === index + 1"> -->
            <span v-if="chapterNum < index + 1"
              ><img src="@/assets/img/10-Level-progress/book-cream.svg" alt=""
            /></span>
            <span v-if="chapterNum > index + 1"
              ><img src="@/assets/img/10-Level-progress/book-100.svg" alt=""
            /></span>
            <span v-if="chapterNum === index + 1"
              ><img
                :src="
                  require(`@/assets/img/10-Level-progress/lesson-${
                    position == 1 ? lesson : lesson - 1
                  }-complete.svg`)
                "
                :class="{ glow: position == 1 && steps == finalSteps }"
                alt=""
            /></span>
          </div>
          <!-- </div> -->
        </div>
      </div>

      <!-- 背景图 -->
      <div class="customlesson-breakthrough-part-movement">
        <div class="picture" ref="pictureBox">
          <div class="picture-item" v-for="(item, index) in 24" :key="index">
            <img
              v-if="index === steps - 1 && steps !== 24"
              ref="content"
              class="background-image"
              :src="
                require(`@/assets/img/09-Level-map/level-${levelNum}/chapter-${chapterString}/map (${
                  index + 1
                }).svg`)
              "
              alt=""
            />
            <img
              v-if="steps === 24"
              ref="content"
              class="background-image"
              :src="
                require(`@/assets/img/09-Level-map/level-${levelNum}/chapter-${chapterString}/map (25).svg`)
              "
              alt=""
            />

            <!-- :class="{ isHidden: index !== steps, fadein: index === steps }" -->
            <img
              v-if="index === steps"
              ref="content"
              :class="{ isHidden: index !== steps, fadein: index === steps }"
              class="background-image"
              :src="
                require(`@/assets/img/09-Level-map/level-${levelNum}/chapter-${chapterString}/map (${
                  index + 1
                }).svg`)
              "
              alt=""
            />
            <img
              v-if="position == 1 && steps == finalSteps && steps !== 24"
              class="animatedstar"
              :class="`chapterStar-${chapterNum}`"
              src="@/assets/img/07-Stars/star-big.svg"
              alt=""
            />
          </div>

          <!-- 跳动的头像 jump avatar-->
          <div :class="`level${levelNum}`">
            <div
              class="image-item"
              :class="`chapter-${chapterNum}`"
              v-for="(item, index) in 8"
              :key="index + 'img'"
            >
              <div
                :class="`step steps-${index + 1}`"
                v-if="index + 1 === imageStep"
              >
                <div class="img-one">
                  <img
                    src="@/assets/img/09-Level-map/bubble-lesson.svg"
                    alt=""
                  />
                </div>
                <div class="img-two">
                  <img
                    src="@/assets/img/12-Vocabulary/lesson-01-wo.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lesson: {
      type: Number,
      require: true,
    },
    chapterNum: {
      type: Number,
      require: true,
    },
    position: {
      type: Number,
      require: true,
    },
    chapterString: {
      type: String,
      require: true,
      default: "1",
    },
    // levelNum:{
    //   type:Number,
    //   require: true,
    //   default:1
    // }
  },
  data() {
    return {
      steps:
        // for lesson 1 , position 1, we want to show map 0 and map 1
        // for lesson 1, position 2, we want to show map 1 and map 2
        // for lesson 2, position 1, we want to show map 3 and map 4
        // for lesson 2, position 2, we want to show map 4 and map 5
        // ...
        this.position == 0
          ? this.lesson * 2 + (this.lesson - 3)
          : this.lesson * 2 + (this.lesson - 2),
      display: false,
      imageStep: this.position == 0 ? this.lesson - 1 : this.lesson,
      isFinishLastStep: false,
      maskshow: false,
      distance: 0,
      picWidth: 0,
      socketEvent: {},
      handleMouseScrollValue: false,
      levelNum: 1,
      isShowLastStep: false,
    };
  },
  //   beforeMount() {
  //     this.timer = setTimeout(() => {
  //       this.pictureWidth();
  //     }, 10);
  //   },
  watch: {
    steps(value) {
      if (value === 24) {
        return;
      }
      if (value >= this.finalSteps) {
        setTimeout(() => {
          this.isFinishLastStep = true;
          this.maskshow = true;
        }, 1500);
        if (value === 23) {
          setTimeout(() => {
            this.steps = 24;
            this.isShowLastStep = true;
          }, 2000);
        }
      }
    },
    isFinishLastStep() {
      setTimeout(() => {
        this.maskshow = false;
      }, 1500);
    },
    socketEvent: {
      handler(value) {
        const data = {
          clickType: 20016,
          data: { value },
          text: "测试rankMapPage组件内scroll事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      },
      deep: true,
    },
  },
  mounted() {
    let str = this.$route.name;
    // console.log(str);
    let num = "";

    for (let key of str) {
      if (parseInt(key) >= 0) {
        num += key;
      }
    }
    let lessonNo = Number(num);
    // console.log(lessonNo);
    if (lessonNo >= 65 && lessonNo <= 128) {
      this.levelNum = 2;
    }

    // console.log(this.$refs.pictureBox);
    this.$nextTick(() => {
      this.picWidth = this.$refs.pictureBox.getBoundingClientRect().width;
      this.timer = setInterval(this.intervalClick, 1000);
      this.$refs.pictureBox.addEventListener(
        "mousewheel",
        this.handleMouseScroll
      );
    });
    if (this.levelNum === 1) {
      if (this.lesson >= 6 && this.chapterNum === 1) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (
        this.lesson >= 3 &&
        this.lesson <= 6 &&
        this.chapterNum === 2
      ) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (this.lesson >= 6 && this.chapterNum === 3) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (this.lesson >= 5 && this.chapterNum == 4) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (this.lesson >= 4 && this.chapterNum === 5) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (
        this.lesson >= 6 &&
        (this.chapterNum === 6 || this.chapterNum === 8)
      ) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (this.lesson >= 5 && this.chapterNum === 7) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      }
    }
    if (this.levelNum === 2) {
      if (
        this.lesson >= 5 &&
        (this.chapterNum === 1 ||
          this.chapterNum === 4 ||
          this.chapterNum === 5 ||
          this.chapterNum === 6 ||
          this.chapterNum === 7)
      ) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (
        (this.lesson >= 6 || this.lesson === 4) &&
        this.chapterNum === 2
      ) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (this.lesson >= 3 && this.chapterNum === 3) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      } else if (this.lesson >= 4 && this.chapterNum === 8) {
        setTimeout(() => {
          this.mapScroll();
        }, 1000);
      }
    }
    this.$bus.$on("scrollMouse", (e) => {
      this.handleMouseScroll(e, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("scrollMouse");
    clearInterval(this.timer);
  },
  computed: {
    finalSteps() {
      if (this.position == 0) {
        // for lesson 1 , position 1, we want to show map 0 and map 1
        // for lesson 1, position 2, we want to show map 1 and map 2
        // for lesson 2, position 1, we want to show map 3 and map 4
        // for lesson 2, position 2, we want to show map 4 and map 5
        // ...
        return this.lesson * 3 - 2;
      } else {
        return this.lesson * 3 - 1;
      }
      // if (this.position) {
      //   return this.lesson * 3;
      // } else {
      //   return (this.lesson - 1) * 3 + 1;
      // }
    },
  },
  methods: {
    // 进入滚动
    mapScroll() {
      this.$refs.pictureBox.scrollLeft = this.$refs.pictureBox.scrollWidth;
    },
    // 滚动实现
    handleMouseScroll(e, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketEvent = { deltaY: e.deltaY, socketIndex: Math.random() };
      }
      const picWidth = this.$refs.pictureBox.getBoundingClientRect().width;
      const speed = e.deltaY > 0 ? 40 : -40;
      let dis;
      this.distance += speed;
      if (this.distance < 0) {
        dis = 0;
        this.distance = 0;
      } else if (this.distance > picWidth) {
        dis = picWidth;
        this.distance = picWidth;
      } else {
        dis = this.distance;
      }
      this.$refs.pictureBox.scrollLeft = dis;
    },

    pictureWidth() {
      let pictureWidth = document.getElementsByClassName("picture")[0];
      pictureWidth.style.width = String(this.$refs.content.width) + "px";
    },
    intervalClick() {
      // console.log(this.finalSteps, "+++++++++++");
      this.pictureWidth();
      this.steps++;
      this.targetChange();
      if (this.steps >= this.finalSteps) {
        clearInterval(this.timer);
        // this.finalSteps = 0;
        // this.steps = 0;
      }
    },
    targetChange() {
      // move the pin when beginning of new level
      if (this.steps == this.finalSteps && this.position == 0) {
        this.imageStep++;
      }
    },
    // 烟花
  },
};
</script>
<style scoped lang="scss">
.rank-map-page-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 56px;
  // z-index: 100;
  .mask-book {
    width: 100%;
    height: 100%;
    border-radius: 56px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    .mask-book-content {
      width: 20%;
      height: 30%;
      background-color: #f4d737;
      border-bottom: 10px solid #d1b726;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 5%;
      position: relative;
      .font28 {
        color: #224e96;
        font-weight: 700;
        margin-bottom: 15%;
      }
      img {
        width: 70%;
      }
      .confetti-img {
        width: 80%;
        position: absolute;
        top: 0;
      }
    }
  }
  .lesson_content {
    width: 100%;
    height: 100%;
    position: relative;
    .customlesson-breakthrough-part {
      position: absolute;
      top: 0%;
      right: 5%;
      height: 40px;
      display: flex;
      padding: 10px 10px;
      background-color: #f4d737;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-bottom: 6px solid #d1b726;
      z-index: 2;
      .demo {
        display: flex;
        .customlesson-breakthrough-part-book {
          margin-left: 5px;
          img {
            width: 25px;
            height: 35px;
          }
        }
      }
    }
    .customlesson-breakthrough-part-movement {
      height: 100%;
      border-radius: 56px;
      background-color: #f7f4e0;

      .picture {
        height: 100%;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;

        .image-item {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
            height: 85%;
            position: absolute;
            bottom: 0;
          }
          .step {
            position: absolute;
            top: 25vh;
            left: 32vh;
            z-index: 5;
          }

          .img-one {
            // width: 100px;
            width: 100px;
            height: 100px;
            z-index: 6;
            img {
              width: 100%;
            }
          }
          .img-two {
            position: absolute;
            top: 5%;
            left: 19%;
            height: 70px;
            img {
              width: 60px;
              height: 60px;
            }
          }
        }
        .level1 {
          .chapter-1 {
            .steps-1 {
              top: 22vh;
              left: 34vh;
            }
            .steps-2 {
              top: 3vh;
              left: 44vh;
            }
            .steps-3 {
              top: 12vh;
              left: 85vh;
            }
            .steps-4 {
              top: 37vh;
              left: 62vh;
            }
            .steps-5 {
              top: 49vh;
              left: 41vh;
            }
            .steps-6 {
              top: 48vh;
              left: 100vh;
            }
            .steps-7 {
              top: 33vh;
              left: 112vh;
            }
            .steps-8 {
              top: 6vh;
              left: 113vh;
            }
          }
          .chapter-2 {
            .steps-1 {
              top: 39vh;
              left: 42vh;
            }
            .steps-2 {
              top: 56vh;
              left: 80vh;
            }
            .steps-3 {
              top: 37vh;
              left: 123vh;
            }
            .steps-4 {
              top: 58vh;
              left: 152vh;
            }
            .steps-5 {
              top: 2vh;
              left: 153vh;
            }
            .steps-6 {
              top: 8vh;
              left: 101vh;
            }
            .steps-7 {
              top: 27vh;
              left: 76vh;
            }
            .steps-8 {
              top: 3vh;
              left: 42vh;
            }
          }
          .chapter-3 {
            .steps-1 {
              top: 22vh;
              left: 42vh;
            }
            .steps-2 {
              top: 12vh;
              left: 78vh;
            }
            .steps-3 {
              top: 36vh;
              left: 78vh;
            }
            .steps-4 {
              top: 49vh;
              left: 43vh;
            }
            .steps-5 {
              top: 60vh;
              left: 96vh;
            }
            .steps-6 {
              top: 52vh;
              left: 112vh;
            }
            .steps-7 {
              top: 34vh;
              left: 121vh;
            }
            .steps-8 {
              top: 9vh;
              left: 139vh;
            }
          }
          .chapter-4 {
            .steps-1 {
              top: 22vh;
              left: 42vh;
            }
            .steps-2 {
              top: 58vh;
              left: 50vh;
            }
            .steps-3 {
              top: 27vh;
              left: 85vh;
            }
            .steps-4 {
              top: 14vh;
              left: 72vh;
            }
            .steps-5 {
              top: 11vh;
              left: 113vh;
            }
            .steps-6 {
              top: 27vh;
              left: 140vh;
            }
            .steps-7 {
              top: 36vh;
              left: 102vh;
            }
            .steps-8 {
              top: 59vh;
              left: 109vh;
            }
          }
          .chapter-5 {
            .steps-1 {
              top: 9vh;
              left: 42vh;
            }
            .steps-2 {
              top: 32vh;
              left: 48vh;
            }
            .steps-3 {
              top: 53vh;
              left: 50vh;
            }
            .steps-4 {
              top: 55vh;
              left: 107vh;
            }
            .steps-5 {
              top: 48vh;
              left: 137vh;
            }
            .steps-6 {
              top: 34vh;
              left: 154vh;
            }
            .steps-7 {
              top: 10vh;
              left: 131vh;
            }
            .steps-8 {
              top: 26vh;
              left: 86vh;
            }
          }
          .chapter-6 {
            .steps-1 {
              top: 13vh;
              left: 34vh;
            }
            .steps-2 {
              top: 38vh;
              left: 48vh;
            }
            .steps-3 {
              top: 18vh;
              left: 86vh;
            }
            .steps-4 {
              top: 33vh;
              left: 107vh;
            }
            .steps-5 {
              top: 55vh;
              left: 68vh;
            }
            .steps-6 {
              top: 60vh;
              left: 146vh;
            }
            .steps-7 {
              top: 31vh;
              left: 160vh;
            }
            .steps-8 {
              top: 13vh;
              left: 162vh;
            }
          }
          .chapter-7 {
            .steps-1 {
              top: 11vh;
              left: 39vh;
            }
            .steps-2 {
              top: 24vh;
              left: 67vh;
            }
            .steps-3 {
              top: 55vh;
              left: 40vh;
            }
            .steps-4 {
              top: 51vh;
              left: 74vh;
            }
            .steps-5 {
              top: 50vh;
              left: 123vh;
            }
            .steps-6 {
              top: 60vh;
              left: 146vh;
            }
            .steps-7 {
              top: 21vh;
              left: 150vh;
            }
            .steps-8 {
              top: 13vh;
              left: 95vh;
            }
          }
          .chapter-8 {
            .steps-1 {
              top: 14vh;
              left: 40vh;
            }
            .steps-2 {
              top: 26vh;
              left: 55vh;
            }
            .steps-3 {
              top: 57vh;
              left: 42vh;
            }
            .steps-4 {
              top: 47vh;
              left: 71vh;
            }
            .steps-5 {
              top: 17vh;
              left: 78vh;
            }
            .steps-6 {
              top: 9vh;
              left: 120vh;
            }
            .steps-7 {
              top: 32vh;
              left: 127vh;
            }
            .steps-8 {
              top: 46vh;
              left: 138vh;
            }
          }
        }

        .level2 {
          .chapter-1 {
            .steps-1 {
              top: 9vh;
              left: 48vh;
            }
            .steps-2 {
              top: 30vh;
              left: 62vh;
            }
            .steps-3 {
              top: 52vh;
              left: 56vh;
            }
            .steps-4 {
              top: 55vh;
              left: 83vh;
            }
            .steps-5 {
              top: 56vh;
              left: 109vh;
            }
            .steps-6 {
              top: 35vh;
              left: 155vh;
            }
            .steps-7 {
              top: 22vh;
              left: 185vh;
            }
            .steps-8 {
              top: 6vh;
              left: 113vh;
            }
          }
          .chapter-2 {
            .steps-1 {
              top: 6vh;
              left: 57vh;
            }
            .steps-2 {
              top: 36vh;
              left: 56vh;
            }
            .steps-3 {
              top: 7vh;
              left: 90vh;
            }
            .steps-4 {
              top: 26vh;
              left: 124vh;
            }
            .steps-5 {
              top: 53vh;
              left: 75vh;
            }
            .steps-6 {
              top: 53vh;
              left: 161vh;
            }
            .steps-7 {
              top: 28vh;
              left: 150vh;
            }
            .steps-8 {
              top: 13vh;
              left: 171vh;
            }
          }
          .chapter-3 {
            .steps-1 {
              top: 16vh;
              left: 47vh;
            }
            .steps-2 {
              top: 35vh;
              left: 78vh;
            }
            .steps-3 {
              top: 43vh;
              left: 114vh;
            }
            .steps-4 {
              top: 42vh;
              left: 163vh;
            }
            .steps-5 {
              top: 18vh;
              left: 184vh;
            }
            .steps-6 {
              top: 5vh;
              left: 160vh;
            }
            .steps-7 {
              top: 15vh;
              left: 132vh;
            }
            .steps-8 {
              top: 16vh;
              left: 97vh;
            }
          }
          .chapter-4 {
            .steps-1 {
              top: 4vh;
              left: 42vh;
            }
            .steps-2 {
              top: 28vh;
              left: 70vh;
            }
            .steps-3 {
              top: 48vh;
              left: 82vh;
            }
            .steps-4 {
              top: 22vh;
              left: 104vh;
            }
            .steps-5 {
              top: 6vh;
              left: 136vh;
            }
            .steps-6 {
              top: 42vh;
              left: 150vh;
            }
            .steps-7 {
              top: 32vh;
              left: 165vh;
            }
            .steps-8 {
              top: 15vh;
              left: 176vh;
            }
          }
          .chapter-5 {
            .steps-1 {
              top: 2vh;
              left: 42vh;
            }
            .steps-2 {
              top: 18vh;
              left: 65vh;
            }
            .steps-3 {
              top: 36vh;
              left: 78vh;
            }
            .steps-4 {
              top: 46vh;
              left: 103vh;
            }
            .steps-5 {
              top: 0vh;
              left: 112vh;
            }
            .steps-6 {
              top: 1vh;
              left: 152vh;
            }
            .steps-7 {
              top: 10vh;
              left: 174vh;
            }
            .steps-8 {
              top: 60vh;
              left: 157vh;
            }
          }
          .chapter-6 {
            .steps-1 {
              top: 21vh;
              left: 52vh;
            }
            .steps-2 {
              top: 11vh;
              left: 93vh;
            }
            .steps-3 {
              top: 36vh;
              left: 95vh;
            }
            .steps-4 {
              top: 55vh;
              left: 107vh;
            }
            .steps-5 {
              top: 48vh;
              left: 137vh;
            }
            .steps-6 {
              top: 34vh;
              left: 168vh;
            }
            .steps-7 {
              top: 24vh;
              left: 132vh;
            }
            .steps-8 {
              top: 13vh;
              left: 152vh;
            }
          }
          .chapter-7 {
            .steps-1 {
              top: 13vh;
              left: 43vh;
            }
            .steps-2 {
              top: 39vh;
              left: 49vh;
            }
            .steps-3 {
              top: 58vh;
              left: 44vh;
            }
            .steps-4 {
              top: 64vh;
              left: 87vh;
            }
            .steps-5 {
              top: 15vh;
              left: 128vh;
            }
            .steps-6 {
              top: 38vh;
              left: 160vh;
            }
            .steps-7 {
              top: 31vh;
              left: 178vh;
            }
            .steps-8 {
              top: 13vh;
              left: 162vh;
            }
          }
          .chapter-8 {
            .steps-1 {
              top: 21vh;
              left: 53vh;
            }
            .steps-2 {
              top: 8vh;
              left: 67vh;
            }
            .steps-3 {
              top: 14vh;
              left: 112vh;
            }
            .steps-4 {
              top: 34vh;
              left: 136vh;
            }
            .steps-5 {
              top: 58vh;
              left: 120vh;
            }
            .steps-6 {
              top: 60vh;
              left: 146vh;
            }
            .steps-7 {
              top: 40vh;
              left: 157vh;
            }
            .steps-8 {
              top: 16vh;
              left: 158vh;
            }
          }
        }
        .picture-item {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
            height: 95%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
      ::-webkit-scrollbar-track {
        background: #efefef;
        border-radius: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-corner {
        background: #179a16;
      }
      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        display: none;
      }
    }
  }
}

.lesson1 {
  .animatedstar {
    top: 6.5vh;
    left: 31.5vh !important;
  }
  .chapterStar-2 {
    top: 25vh !important;
    left: 63.5vh !important;
  }
  .chapterStar-3 {
    top: -3.2vh !important;
    left: 72.1vh !important;
  }
  .chapterStar-4 {
    top: 3.5vh !important;
    left: 53.1vh !important;
  }
  .chapterStar-5 {
    top: -16.7vh !important;
    left: 55.1vh !important;
  }
  .chapterStar-6 {
    top: -12.2vh !important;
    left: 50vh !important;
  }
  .chapterStar-7 {
    top: -14.1vh !important;
    left: 31.1vh !important;
  }
  .chapterStar-8 {
    top: -10.6vh;
    left: 47.1vh !important;
  }
}

.lesson2 {
  .animatedstar {
    top: -14vh;
    left: 42.5vh !important;
  }
  .chapterStar-2 {
    top: 36.5vh !important;
    left: 93.5vh !important;
  }
  .chapterStar-3 {
    top: -13.1vh !important;
    left: 104.2vh !important;
  }
  .chapterStar-4 {
    top: 34.8vh !important;
    left: 81.1vh !important;
  }
  .chapterStar-5 {
    top: 8.4vh !important;
    left: 68vh !important;
  }
  .chapterStar-6 {
    top: 12.9vh !important;
    left: 35vh !important;
  }
  .chapterStar-7 {
    top: 1vh !important;
    left: 45.3vh !important;
  }
  .chapterStar-8 {
    top: 4.7vh;
    left: 63.4vh !important;
  }
}

.lesson3 {
  .animatedstar {
    top: -5vh;
    left: 66.5vh !important;
  }
  .chapterStar-2 {
    top: 17vh !important;
    left: 109.5vh !important;
  }
  .chapterStar-3 {
    top: 10.8vh !important;
    left: 103.8vh !important;
  }
  .chapterStar-4 {
    top: 2.8vh !important;
    left: 70.1vh !important;
  }
  .chapterStar-5 {
    top: 32.8vh !important;
    left: 90.8vh !important;
  }
  .chapterStar-6 {
    top: -6.5vh !important;
    left: 73.7vh !important;
  }
  .chapterStar-7 {
    top: 31.5vh !important;
    left: 24.7vh !important;
  }
  .chapterStar-8 {
    top: 11.7vh;
    left: 68.4vh !important;
  }
}

.lesson4 {
  .animatedstar {
    top: 8vh;
    left: 76vh !important;
  }
  .chapterStar-2 {
    top: 39vh !important;
    left: 119.5vh !important;
  }
  .chapterStar-3 {
    top: 23.5vh !important;
    left: 27vh !important;
  }
  .chapterStar-4 {
    top: -9.7vh !important;
    left: 89.1vh !important;
  }
  .chapterStar-5 {
    top: 31.8vh !important;
    left: 117.8vh !important;
  }
  .chapterStar-6 {
    top: 9.3vh !important;
    left: 96.1vh !important;
  }
  .chapterStar-7 {
    top: 24.8vh !important;
    left: 72.9vh !important;
  }
  .chapterStar-8 {
    top: 25.5vh;
    left: 98.4vh !important;
  }
}
.lesson5 {
  .animatedstar {
    top: 26vh;
    left: 39.5vh !important;
  }
  .chapterStar-2 {
    top: -20vh !important;
    left: 150.5vh !important;
  }
  .chapterStar-3 {
    top: 34.8vh !important;
    left: 113.4vh !important;
  }
  .chapterStar-4 {
    top: -12vh !important;
    left: 134.1vh !important;
  }
  .chapterStar-5 {
    top: 23.9vh !important;
    left: 162.7vh !important;
  }
  .chapterStar-6 {
    top: 31.3vh !important;
    left: 55.1vh !important;
  }
  .chapterStar-7 {
    top: 24.6vh !important;
    left: 103.7vh !important;
  }
  .chapterStar-8 {
    top: -4.2vh;
    left: 103.7vh !important;
  }
}
.lesson6 {
  .animatedstar {
    top: 31vh;
    left: 136.5vh !important;
  }
  .chapterStar-2 {
    top: -12vh !important;
    left: 97.5vh !important;
  }
  .chapterStar-3 {
    top: 26.4vh !important;
    left: 154.6vh !important;
  }
  .chapterStar-4 {
    top: 3vh !important;
    left: 131.1vh !important;
  }
  .chapterStar-5 {
    top: 8.5vh !important;
    left: 177vh !important;
  }
  .chapterStar-6 {
    top: 35.5vh !important;
    left: 130vh !important;
  }
  .chapterStar-7 {
    top: 11.5vh !important;
    left: 122vh !important;
  }
  .chapterStar-8 {
    top: -12.2vh;
    left: 144.7vh !important;
  }
}
.lesson7 {
  .animatedstar {
    top: 5.5vh;
    left: 112.5vh !important;
  }
  .chapterStar-2 {
    top: 10vh !important;
    left: 104.5vh !important;
  }
  .chapterStar-3 {
    top: 8.5vh !important;
    left: 113vh !important;
  }
  .chapterStar-4 {
    top: 13vh !important;
    left: 89.1vh !important;
  }
  .chapterStar-5 {
    top: -14.7vh !important;
    left: 128.4vh !important;
  }
  .chapterStar-6 {
    top: 6.3vh !important;
    left: 175.3vh !important;
  }
  .chapterStar-7 {
    top: -2vh !important;
    left: 146.6vh !important;
  }
  .chapterStar-8 {
    top: 7.5vh;
    left: 134.5vh !important;
  }
}

.lesson8 {
  .animatedstar {
    top: -8.5vh;
    left: 148vh !important;
  }
  .chapterStar-2 {
    top: -11.5vh !important;
    left: 26.5vh !important;
  }
  .chapterStar-3 {
    top: -5.6vh !important;
    left: 153.2vh !important;
  }
  .chapterStar-4 {
    top: 35vh !important;
    left: 159.1vh !important;
  }
  .chapterStar-5 {
    top: 2.2vh !important;
    left: 84.8vh !important;
  }
  .chapterStar-6 {
    top: -11.7vh !important;
    left: 158.4vh !important;
  }
  .chapterStar-7 {
    top: -10.4vh !important;
    left: 97.4vh !important;
  }
  .chapterStar-8 {
    top: 33.5vh;
    left: 153.5vh !important;
  }
}

//  fade in animation for transition
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadein {
  color: white;
  -webkit-animation: fadein 0.5s ease-in forwards;
  -moz-animation: fadein 0.5s ease-in forwards;
  animation: fadein 0.5s ease-in forwards;
}

.animatedstar {
  width: 4.3vh;
  z-index: 6;
  -webkit-animation: spin 0.8s linear infinite;
  -moz-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
  animation-direction: reverse;
  -webkit-animation-iteration-count: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    -moz-transform: scale(1) rotate(0deg);
    position: absolute;
  }
  10% {
  }
  20% {
    -moz-transform: scale(3.5) rotate(90deg);
    transform: scale(1.5) rotate(60deg);
    position: absolute;
  }
  50% {
  }
  80% {
    opacity: 0.8;
    position: absolute;
  }
  100% {
    -moz-transform: scale(6.5) rotate(360deg);
    transform: scale(6.5) rotate(360deg);

    position: absolute;
    opacity: 0;
  }
}

.glow {
  animation: glow 1s;
}
@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #2472b3;
  }
  to {
    box-shadow: 0 0 10px 10px #2472b3;
  }
}
</style>
