var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rank-map-page-container", class: `lesson` + _vm.lesson },
    [
      _c("transition", { attrs: { name: "el-fade-in" } }, [
        _vm.position === 1 && _vm.isFinishLastStep && _vm.maskshow
          ? _c("div", { staticClass: "mask-book" }, [
              _c("div", { staticClass: "mask-book-content" }, [
                _c("span", { staticClass: "font28" }, [
                  _vm._v("CHAPTER " + _vm._s(_vm.chapterNum)),
                ]),
                _c("img", {
                  class: {
                    glow: _vm.position == 1 && _vm.steps == _vm.finalSteps,
                  },
                  attrs: {
                    src: require(`@/assets/img/10-Level-progress/progress-level${_vm.lesson}.gif`),
                    alt: "",
                  },
                }),
                _c("img", {
                  staticClass: "confetti-img",
                  attrs: {
                    src: require("@/assets/img/07-Stars/confetti.svg"),
                    alt: "",
                  },
                }),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "lesson_content" }, [
        _c("div", { staticClass: "customlesson-breakthrough-part" }, [
          _c(
            "div",
            { staticClass: "demo" },
            _vm._l(8, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "customlesson-breakthrough-part-book",
                },
                [
                  _vm.chapterNum < index + 1
                    ? _c("span", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/book-cream.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.chapterNum > index + 1
                    ? _c("span", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/book-100.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.chapterNum === index + 1
                    ? _c("span", [
                        _c("img", {
                          class: {
                            glow:
                              _vm.position == 1 && _vm.steps == _vm.finalSteps,
                          },
                          attrs: {
                            src: require(`@/assets/img/10-Level-progress/lesson-${
                              _vm.position == 1 ? _vm.lesson : _vm.lesson - 1
                            }-complete.svg`),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "customlesson-breakthrough-part-movement" }, [
          _c(
            "div",
            { ref: "pictureBox", staticClass: "picture" },
            [
              _vm._l(24, function (item, index) {
                return _c("div", { key: index, staticClass: "picture-item" }, [
                  index === _vm.steps - 1 && _vm.steps !== 24
                    ? _c("img", {
                        ref: "content",
                        refInFor: true,
                        staticClass: "background-image",
                        attrs: {
                          src: require(`@/assets/img/09-Level-map/level-${
                            _vm.levelNum
                          }/chapter-${_vm.chapterString}/map (${
                            index + 1
                          }).svg`),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm.steps === 24
                    ? _c("img", {
                        ref: "content",
                        refInFor: true,
                        staticClass: "background-image",
                        attrs: {
                          src: require(`@/assets/img/09-Level-map/level-${_vm.levelNum}/chapter-${_vm.chapterString}/map (25).svg`),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  index === _vm.steps
                    ? _c("img", {
                        ref: "content",
                        refInFor: true,
                        staticClass: "background-image",
                        class: {
                          isHidden: index !== _vm.steps,
                          fadein: index === _vm.steps,
                        },
                        attrs: {
                          src: require(`@/assets/img/09-Level-map/level-${
                            _vm.levelNum
                          }/chapter-${_vm.chapterString}/map (${
                            index + 1
                          }).svg`),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm.position == 1 &&
                  _vm.steps == _vm.finalSteps &&
                  _vm.steps !== 24
                    ? _c("img", {
                        staticClass: "animatedstar",
                        class: `chapterStar-${_vm.chapterNum}`,
                        attrs: {
                          src: require("@/assets/img/07-Stars/star-big.svg"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ])
              }),
              _c(
                "div",
                { class: `level${_vm.levelNum}` },
                _vm._l(8, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index + "img",
                      staticClass: "image-item",
                      class: `chapter-${_vm.chapterNum}`,
                    },
                    [
                      index + 1 === _vm.imageStep
                        ? _c("div", { class: `step steps-${index + 1}` }, [
                            _vm._m(0, true),
                            _vm._m(1, true),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-one" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/09-Level-map/bubble-lesson.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-two" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/12-Vocabulary/lesson-01-wo.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }