export const lessonMixin = {
    data() {
        return {
            pageNum: 1,
            globalWhiteB: false,
            pageType: 0,
        }
    },
    mounted() {
        // 接收主页面的canvas展示事件
        this.$bus.$on("openPageWhiteBoard", () => {
            console.log("打开页面白板");
            this.globalWhiteB = false;
        });
        this.$bus.$on("OpenGlobalWhiteBoard", (val) => {
            console.log("打开全局白板");
            this.globalWhiteB = val;
        });
        // 接收所有课程页面的next按钮事件
        this.$bus.$on("nextButton", () => {
            // console.log("下一页", val);
            this.pageNum++;
            if (this.pageType === 1) {
                this.pageType = 0
            }
            let changePage = {
                val: this.pageNum,
                type: 2
            }
            this.$bus.$emit("changePageIndex", changePage)
        });
        // 接收header部分的页面跳转事件
        this.$bus.$on("changeCoursePage", (index) => {
            this.pageNum = index;
            if (this.pageType === 1) {
                this.pageType = 0
                this.$bus.$emit("changeTitleActive", this.pageType)
            }
        });
        // 接收header部分的页面展示跳转事件
        this.$bus.$on("switchPageType", (index) => {
            console.log("页面类型:", index);
            this.pageType = index;
            this.$bus.$emit("changeTitleActive", index, true)
        });
    },
    destroyed() {
        this.$bus.$off("openPageWhiteBoard");
        this.$bus.$off("OpenGlobalWhiteBoard");
        this.$bus.$off("nextButton");
        this.$bus.$off("changeCoursePage");
        this.$bus.$off("switchWhiteBoard");

    },
    methods: {
        // fred 的代码
        enter(el) {
            el.className += " fadein ";
            setTimeout(() => {
                el.classList.remove("fadein");
            }, 2000);
        },
        beforeEnter() {},
    },
}